import React from "react";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import LightModeIcon from '@mui/icons-material/LightMode';
import LooksOneIcon from '@mui/icons-material/LooksOne';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import ScheduleIcon from '@mui/icons-material/Schedule';
import FlagIcon from '@mui/icons-material/Flag';
import WavingHandIcon from '@mui/icons-material/WavingHand';
import FmdGoodIcon from '@mui/icons-material/FmdGood';
import DirectionsIcon from '@mui/icons-material/Directions';
import DirectionsSubwayFilledIcon from '@mui/icons-material/DirectionsSubwayFilled';
import NoFoodIcon from '@mui/icons-material/NoFood';
import FamilyRestroomIcon from '@mui/icons-material/FamilyRestroom';
import HomeIcon from '@mui/icons-material/Home';
import SchoolIcon from '@mui/icons-material/School';
import CorporateFareIcon from '@mui/icons-material/CorporateFare';
import TsunamiIcon from '@mui/icons-material/Tsunami';
import PaymentIcon from '@mui/icons-material/Payment';
import WorkIcon from '@mui/icons-material/Work';
import AnimationIcon from '@mui/icons-material/Animation';
import ScatterPlotIcon from '@mui/icons-material/ScatterPlot';
import JoinInnerIcon from '@mui/icons-material/JoinInner';

const ListItems = ({ resetUserSelectedData }) => {
  const handleClick = (selectedSet) => {
    resetUserSelectedData(selectedSet);
  };

  return (
    <React.Fragment>
      <ListItemButton onClick={() => handleClick("0")}>
        <ListItemIcon>
          <LooksOneIcon />
        </ListItemIcon>
        <ListItemText primary="Numbers" />
      </ListItemButton>
      <ListItemButton onClick={() => handleClick("1")}>
        <ListItemIcon>
          <LightModeIcon />
        </ListItemIcon>
        <ListItemText primary="Days" />
      </ListItemButton>
      <ListItemButton onClick={() => handleClick("2")}>
        <ListItemIcon>
          <ScheduleIcon />
        </ListItemIcon>
        <ListItemText primary="Time" />
      </ListItemButton>
      <ListItemButton onClick={() => handleClick("3")}>
        <ListItemIcon>
          <CalendarMonthIcon />
        </ListItemIcon>
        <ListItemText primary="Calendar" />
      </ListItemButton>
      <ListItemButton onClick={() => handleClick("4")}>
        <ListItemIcon>
          <FlagIcon />
        </ListItemIcon>
        <ListItemText primary="Country" />
      </ListItemButton>
      <ListItemButton onClick={() => handleClick("5")}>
        <ListItemIcon>
          <WavingHandIcon />
        </ListItemIcon>
        <ListItemText primary="Greeting" />
      </ListItemButton>
      <ListItemButton onClick={() => handleClick("6")}>
        <ListItemIcon>
          <FmdGoodIcon />
        </ListItemIcon>
        <ListItemText primary="Location" />
      </ListItemButton>
      <ListItemButton onClick={() => handleClick("7")}>
        <ListItemIcon>
          <DirectionsIcon />
        </ListItemIcon>
        <ListItemText primary="Direction" />
      </ListItemButton>
      <ListItemButton onClick={() => handleClick("8")}>
        <ListItemIcon>
          <DirectionsSubwayFilledIcon />
        </ListItemIcon>
        <ListItemText primary="Transportation" />
      </ListItemButton>
      <ListItemButton onClick={() => handleClick("9")}>
        <ListItemIcon>
          <NoFoodIcon />
        </ListItemIcon>
        <ListItemText primary="Food" />
      </ListItemButton>
      <ListItemButton onClick={() => handleClick("10")}>
        <ListItemIcon>
          <FamilyRestroomIcon />
        </ListItemIcon>
        <ListItemText primary="Relationship" />
      </ListItemButton>
      <ListItemButton onClick={() => handleClick("11")}>
        <ListItemIcon>
          <HomeIcon />
        </ListItemIcon>
        <ListItemText primary="Home" />
      </ListItemButton>
      <ListItemButton onClick={() => handleClick("12")}>
        <ListItemIcon>
          <SchoolIcon />
        </ListItemIcon>
        <ListItemText primary="School" />
      </ListItemButton>
      <ListItemButton onClick={() => handleClick("13")}>
        <ListItemIcon>
          <CorporateFareIcon />
        </ListItemIcon>
        <ListItemText primary="Office" />
      </ListItemButton>
      <ListItemButton onClick={() => handleClick("14")}>
        <ListItemIcon>
          <TsunamiIcon />
        </ListItemIcon>
        <ListItemText primary="Season" />
      </ListItemButton>
      <ListItemButton onClick={() => handleClick("15")}>
        <ListItemIcon>
          <PaymentIcon />
        </ListItemIcon>
        <ListItemText primary="Payment" />
      </ListItemButton>
      <ListItemButton onClick={() => handleClick("16")}>
        <ListItemIcon>
          <WorkIcon />
        </ListItemIcon>
        <ListItemText primary="Profession" />
      </ListItemButton>
      <ListItemButton onClick={() => handleClick("17")}>
        <ListItemIcon>
          <AnimationIcon />
        </ListItemIcon>
        <ListItemText primary="Verb" />
      </ListItemButton>
      <ListItemButton onClick={() => handleClick("18")}>
        <ListItemIcon>
          <ScatterPlotIcon />
        </ListItemIcon>
        <ListItemText primary="Particle" />
      </ListItemButton>
      <ListItemButton onClick={() => handleClick("19")}>
        <ListItemIcon>
          <JoinInnerIcon />
        </ListItemIcon>
        <ListItemText primary="Adjective" />
      </ListItemButton>
      <ListItemButton onClick={() => handleClick("20")}>
        <ListItemIcon>
          <JoinInnerIcon />
        </ListItemIcon>
        <ListItemText primary="Counters" />
      </ListItemButton>
      <ListItemButton onClick={() => handleClick("21")}>
        <ListItemIcon>
          <JoinInnerIcon />
        </ListItemIcon>
        <ListItemText primary="Kanji" />
      </ListItemButton>
    </React.Fragment>
  );
};

export default ListItems;
