//create one state variable which will keep array of question, answer and correct answer
import NumberSet from "../data/json/NumberSet.json";
import DaySet from "../data/json/DaySet.json";
import TimeSet from "../data/json/TimeSet.json";
import CalendarSet from "../data/json/CalendarSet.json";
import CountrySet from "../data/json/CountrySet.json";
import GreetingSet from "../data/json/GreetingSet.json";
import LocationSet from "../data/json/LocationSet.json";
import DirectionSet from "../data/json/DirectionSet.json";
import TransportationSet from "../data/json/TransportationSet.json";
import FoodSet from "../data/json/FoodSet.json";
import RelationshipSet from "../data/json/RelationshipSet.json";
import HomeSet from "../data/json/HomeSet.json";
import SchoolSet from "../data/json/SchoolSet.json";
import OfficeSet from "../data/json/OfficeSet.json";
import SeasonSet from "../data/json/SeasonSet.json";
import PaymentSet from "../data/json/PaymentSet.json";
import ProfessionSet from "../data/json/ProfessionSet.json";
import VerbSet from "../data/json/VerbSet.json";
import ParticleSet from "../data/json/ParticleSet.json";
import AdjectiveSet from "../data/json/AdjectiveSet.json";
import CounterSet from "../data/json/CounterSet.json";
import KanjiSet from "../data/json/KanjiSet.json";

export const mergedQuestionSet = [
  ...NumberSet,
  ...DaySet,
  ...TimeSet,
  ...CalendarSet,
  ...CountrySet,
  ...GreetingSet,
  ...LocationSet,
  ...DirectionSet,
  ...TransportationSet,
  ...FoodSet,
  ...RelationshipSet,
  ...HomeSet,
  ...SchoolSet,
  ...OfficeSet,
  ...SeasonSet,
  ...PaymentSet,
  ...ProfessionSet,
  ...VerbSet,
  ...ParticleSet,
  ...AdjectiveSet,
  ...CounterSet,
  ...KanjiSet
];
